<script setup lang="ts">
import Dialog from 'primevue/dialog'
import { ref } from 'vue'

const display = ref(false)
const _message = ref("")
const _confirmCallback = ref()
const _cancelCallback = ref()

function showConfirm(message: string, confirmCallback: Function, cancelCallback: Function) {
  display.value = true
  _message.value = message
  _confirmCallback.value = confirmCallback
  _cancelCallback.value = cancelCallback
}

function closeConfirm() {
  display.value = false
}

defineExpose({
  showConfirm, closeConfirm
})

async function confirm() {
  display.value = false
  await _confirmCallback.value()
}

async function cancel() {
  display.value = false
  await _cancelCallback.value()
}

</script>
<template>
  <Dialog v-model:visible="display" :draggable="false" class="custom-dialog" :closable="false" modal>
    <template #header>
      <p class="dialog-header">{{ $t('popup.confirm_header') }}</p>
    </template>
    <div>
      <p class="dialog-des" style="margin-bottom: 0; white-space: pre-line;">{{ _message }}</p>
    </div>
    <template #footer>
      <div class="footer-btn-box">
        <button class="custom btn action-btn outline" style="margin-right: 20px;" @click="cancel">{{ $t('common.cancel')
          }}</button>
        <button class="custom btn action-btn active" @click="confirm">{{ $t('common.okay') }}</button>
      </div>
    </template>
  </Dialog>
</template>
<style lang="scss" scoped>
@import "@/style/default.scss";
@import "@/style/dialog.scss";
</style>